.footer-root {
    height: 25vh;
    width: 100vw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    gap: 1rem
}

.footer-logo {
    height: 10vh;
    width: auto;
}
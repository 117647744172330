.custom-shape-divider-bottom-1643407376 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  margin-top: -92px;
}

.custom-shape-divider-bottom-1643407376 svg {
  width: calc(100% + 1.3px);
  height: 92px;
}

.custom-shape-divider-bottom-1643407376 .shape-fill {
  fill: #feea4a;
  position: relative;
}

.third-curves {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: -45px;
}

.third-curves svg {
    width: calc(100% + 1.3px);
    height: 45px;
}

.third-curves .shape-fill {
    fill: #fff;
    position: relative;
}
.main-logo {
  top: 0;
  left: 0;
  filter: drop-shadow(8px 8px 0px rgba(0, 0, 0, 0.3));
  z-index: 1;
  height: calc(65%);
}

ul {
  list-style-type: none;
  padding: 0px 20px 0px 0px;
  display: flex;
  justify-content: space-betweens;
  align-items: center;
  column-gap: 20px;
}

.navbar li {
  border-top-right-radius: 30px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s ease;
  padding: 0.4rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.navbar li:nth-child(1):hover,
.navbar li.active:nth-child(1) {
  background-color: #f1594f;
}

.navbar li:nth-child(2):hover,
.navbar li.active:nth-child(2) {
  background-color: #f17950;
}

.navbar li:nth-child(3):hover,
.navbar li.active:nth-child(3) {
  background-color: #f9bd41;
}

.navbar li:nth-child(4):hover,
.navbar li.active:nth-child(4) {
  background-color: #fde735;
}

.navbar li:nth-child(5):hover,
.navbar li.active:nth-child(5) {
  background-color: #32b2aa;
}

.navbar li:hover,
.navbar li.active {
  box-shadow: 8px 8px 1px rgba(0, 0, 0, 0.4);
  font-size: 1.7rem;
  color: white;
  cursor: pointer;
}

.nav-btns {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-left: 4rem;
}

.nav-btn {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: inherit;
  font-size: 3rem;
  padding-top: 0.75rem;
  transition: all ease 0.5s;
}

.nav-btn a {
  text-decoration: none;
  color: inherit;
}

.nav-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.youtube:hover {
  color: #f1594f;
}

.facebook:hover {
  color: #4566ab;
}

.instagram:hover {
  color: #833ab4;
}

.second-curves {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-top: -92px;
}

.second-curves svg {
    width: calc(100% + 1.3px);
    height: 92px;
}

.second-curves .shape-fill {
    fill: #F1594F;
    position: relative;
}
